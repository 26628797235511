import {
  type ISubmitYieldOptimizationMutation,
  type ISubmitYieldOptimizationMutationVariables,
  type IYieldOptimizerWizardInputQuery,
  useSubmitYieldOptimizationMutation,
  useYieldOptimizerWizardInputSuspenseQuery,
} from '../../../../../generated/graphql.tsx';
import { type ReactElement, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import gYupResolver from '../../../../technical/form/gYupResolver.ts';
import { type YieldOptimizerInputFields, schema } from './YieldOptimizer.validation.ts';
import { useSteps } from '../../../../technical/wizard/UseSteps.ts';
import { config as descriptionStepConfig } from '../description/DescriptionStepConfig.tsx';
import { config as submitConfig } from './submit/SubmitStepConfig.tsx';
import { useGraphQLApiError } from '../../../../technical/form/UseGraphQLApiError.tsx';
import { OptimizationType } from '../../optimization.utils.ts';
import OptimizerWizard from '../portfolio/OptimizerWizard.tsx';
import { config as initialAssetsStepConfig } from './initialAssets/InitialAssetsStepConfig.tsx';
import { config as poolUniverseStepConfig } from './poolUniverse/PoolUniverseStepConfig.tsx';
import { config as allocationConstraintConfig } from './allocationConstraints/AllocationConstraintsStepConfig.tsx';
import { bignumber, type BigNumber } from 'mathjs';
import { isNil, uniqBy } from 'lodash/fp';
import { createRequestInput } from './YieldOptimizerRequestFactory.ts';
import type { YieldOptimizerOutputFields } from './YieldOptimizer.validation.ts';

const YieldOptimizerWizardContainer = () => {
  const yieldOptimizerWizardInputQuery = useYieldOptimizerWizardInputSuspenseQuery();

  const { yieldOptimization, portfolio } = yieldOptimizerWizardInputQuery.data;
  return <YieldOptimizerWizard pools={yieldOptimization.listPools} positions={portfolio.positions.positions} />;
};

const YieldOptimizerWizard = ({
  pools,
  positions,
}: {
  pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'];
  positions: IYieldOptimizerWizardInputQuery['portfolio']['positions']['positions'];
}): ReactElement => {
  const availableAssets = useMemo(
    () =>
      uniqBy(
        (asset) => asset.id,
        pools.map((pool) => pool.collateralAsset)
      ),
    [pools]
  );

  const methods = useForm<YieldOptimizerInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      universe: [],
      constraints: [],
      givenPortfolio: {},
    },
  });

  const stepApi = useSteps();
  const { goToStep, validateVisitedSteps } = stepApi;

  const name = useWatch({
    name: 'name',
    control: methods.control,
  });

  const steps = useMemo(() => {
    let nextIndex = 1;
    const nextHandler = (): (() => void) => {
      const index = nextIndex++;
      return (): void => goToStep(index);
    };

    const availableAssetIds = new Set(availableAssets.map((asset) => asset.id));
    const groupedAssetAmount = new Map<string, BigNumber>();

    for (const pos of positions) {
      const assetId = pos.asset.id;
      if (!availableAssetIds.has(assetId)) {
        continue;
      }

      if (isNil(pos.spot)) {
        continue;
      }

      const currentValue = groupedAssetAmount.get(assetId) ?? bignumber(0);
      groupedAssetAmount.set(assetId, currentValue.add(pos.spot.amount));
    }

    return [
      descriptionStepConfig(nextHandler()),
      initialAssetsStepConfig(availableAssets, groupedAssetAmount, nextHandler()),
      poolUniverseStepConfig(pools, nextHandler()),
      allocationConstraintConfig(nextHandler()),
      submitConfig(),
    ];
  }, [goToStep, pools, positions, availableAssets]);

  validateVisitedSteps({
    trigger: methods.trigger,
    steps,
  });
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [submitOptimization] = useSubmitYieldOptimizationMutation();

  return (
    <OptimizerWizard<
      YieldOptimizerInputFields,
      ISubmitYieldOptimizationMutationVariables['input'],
      ISubmitYieldOptimizationMutation
    >
      type={OptimizationType.yield}
      createRequestInput={(input) => createRequestInput(input as unknown as YieldOptimizerOutputFields)}
      methods={methods}
      name={name}
      onErrorAndThrow={onErrorAndThrow}
      stepApi={stepApi}
      steps={steps}
      submitOptimization={submitOptimization}
      getOptimizationId={(result) => result.optimization.id}
    />
  );
};

export default YieldOptimizerWizardContainer;
