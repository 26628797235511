import {
  IPortfolioAnalysisStatusType,
  type IUnifiedPnlReportListInputQuery,
  useUnifiedPnlReportListInputQuery,
} from '../../../../generated/graphql.tsx';
import type { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { dateReadableValueGetter, getRowDataForRowGroupColumn } from '../../../technical/grids/agGrid.utils.tsx';
import { DateTimeFormat, formatEnum } from '../../../formatter.utils.ts';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { Chip, Stack } from '@mui/joy';
import Loader from '../../../technical/Loader/Loader.tsx';
import GAgGrid from '../../../technical/grids/GAgGrid.tsx';
import { GraphQLErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import { AssetIconList } from '../../../market/asset/AssetIconList.tsx';

const isInProgress = (status: IPortfolioAnalysisStatusType): boolean => {
  return [IPortfolioAnalysisStatusType.Pending, IPortfolioAnalysisStatusType.InProgress].includes(status);
};

type RowData = IUnifiedPnlReportListInputQuery['portfolio']['unifiedPnlStatus'][number];

const UnifiedPnlReportList = ({ onReportSelected }: { onReportSelected: (id: number) => void }): ReactElement => {
  const [refreshImports, setRefreshImports] = useState(true);
  const unifiedPnlStatusQuery = useUnifiedPnlReportListInputQuery({
    pollInterval: refreshImports ? 5000 : 0,
  });

  const columns: ColDef<RowData>[] = useMemo(
    () => [
      {
        headerName: 'Processing start',
        type: 'dateColumn',
        initialSort: 'desc',
        valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
          // backend returns string instead of arrow/date
          return params.data ? dateReadableValueGetter(params.data.createdAt, DateTimeFormat.DateTime) : undefined;
        },
        suppressHeaderMenuButton: true,
        floatingFilter: false,
        filter: null,
      },
      {
        headerName: 'Status',
        type: 'textColumn',
        field: 'status',
        width: 120,
        cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
          const rowData = getRowDataForRowGroupColumn(params);
          if (!rowData) {
            return undefined;
          }

          if (isInProgress(rowData.status)) {
            return (
              <Stack height={'100%'} alignItems={'center'} direction={'row'}>
                <Loader variant={'small'} positionVariant={'min-content'} />
              </Stack>
            );
          }

          return <div>{formatEnum(rowData.status)}</div>;
        },
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Date range',
        type: ['textColumn'],
        valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          // backend returns string instead of arrow/date
          const since = dateReadableValueGetter(params.data.since, DateTimeFormat.Date);
          const to = dateReadableValueGetter(params.data.until, DateTimeFormat.Date);
          return `${since} - ${to}`;
        },
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Dimension',
        type: ['textColumn'],
        suppressHeaderMenuButton: true,
        valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return params.data.subFundDimension ?? 'Organization';
        },
      },
      {
        headerName: 'Assets',
        suppressHeaderMenuButton: true,
        cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
          return (
            <Stack direction={'row'} alignItems={'center'} height={'100%'}>
              <AssetIconList assets={params.data?.assets ?? []} />
            </Stack>
          );
        },
      },
      {
        headerName: 'Actions',
        width: 100,
        cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
          const rowData = getRowDataForRowGroupColumn(params);
          if (!rowData) {
            return undefined;
          }

          if (rowData.status !== IPortfolioAnalysisStatusType.Completed) {
            return undefined;
          }

          return (
            <Chip onClick={() => onReportSelected(rowData.id)} color={'primary'} variant={'solid'}>
              Open
            </Chip>
          );
        },
        suppressHeaderMenuButton: true,
      },
    ],
    [onReportSelected]
  );

  const calculations = unifiedPnlStatusQuery.data?.portfolio.unifiedPnlStatus;
  useEffect(() => {
    if (!calculations) {
      return;
    }

    const hasPending = calculations.some((calc) => isInProgress(calc.status));
    setRefreshImports(hasPending);
  }, [calculations]);

  return (
    <Stack rowGap={2}>
      <GraphQLErrorMessage error={unifiedPnlStatusQuery.error} />
      <GAgGrid<RowData>
        rowData={calculations ?? unifiedPnlStatusQuery.previousData?.portfolio.unifiedPnlStatus}
        height={500}
        suppressCellFocus
        columnDefs={columns}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
      />
    </Stack>
  );
};

export default UnifiedPnlReportList;
