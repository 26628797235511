import type { CellClassParams, ICellRendererParams } from 'ag-grid-community';
import { get, isDate, isNil, isObject } from 'lodash/fp';
import { isBigNumber } from 'mathjs';
import type { IAsset, IBaseAsset } from 'generated/graphql';
import type { ReactElement } from 'react';
import AssetLabel from '../../market/asset/AssetLabel';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import { getAssetName } from '../../market/asset/AssetService';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import dayjs from 'dayjs';
import { UTC } from 'components/date.utils';
import type { AssetLabelInput, NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';

/**
 * When column added to row group, we don't have access to whole row data, only to column value ("group by" value)
 * and to render components asset/sub-account/venue we need more fields, we retrieve data from the first leaf node of the group
 * data is grouped by current column so all leaf nodes should have the same value
 */
export function getRowDataForRowGroupColumn<T>(params: ICellRendererParams<T> | CellClassParams<T>): T | undefined {
  if (params.data) {
    // normal row case
    return params.data;
  }

  // use value property to figure out if row would be rendered normally by aggrid
  if (!isNil(params.value)) {
    return params.node.allLeafChildren?.[0]?.data;
  }

  return undefined;
}

export const sideAwareHeaderName = (headerName: string, sideAware: boolean): string =>
  sideAware ? headerName : `${headerName} ABS`;

export function getValueFormatterCellValue<TValue>(value: TValue | null | undefined): TValue | null | undefined {
  if (isNil(value)) {
    return value;
  }

  if (isBigNumber(value)) {
    return value;
  }

  // for grouped rows value is object {count, value}
  if (isObject(value) && !isDate(value)) {
    if (!('value' in value)) {
      return value.toString() as TValue;
    }

    return getValueFormatterCellValue(value.value) as TValue | null | undefined;
  }

  return value;
}

export function caseInsensitiveComparator(valueA: string | undefined, valueB: string | undefined): number {
  return (valueA ?? '').toLowerCase().localeCompare((valueB ?? '').toLowerCase());
}

export type DerivativeAssetNameRow = {
  asset: AssetLabelInput | NotVerifiedAsset;
};

export type AssetNameRow = {
  asset: Pick<IAsset, 'type' | 'symbol' | 'name'>;
};

export function createAssetCellRenderer<T extends string>(
  field: T
): (params: ICellRendererParams<unknown>) => ReactElement | undefined {
  return (params: ICellRendererParams<unknown>): ReactElement | undefined => {
    const rowData = getRowDataForRowGroupColumn(params);
    const val = get(field, rowData);
    if (isNil(val)) {
      return undefined;
    }

    return <AssetLabel asset={val} wrap={false} size={IconVariant.MEDIUM} plain link />;
  };
}

export const assetCellRenderer = createAssetCellRenderer('asset');

export function derivativeAssetCellRenderer(
  params: ICellRendererParams<{
    asset: Pick<IAsset, 'type' | 'symbol' | 'name'> & {
      derivativeDetails?: { baseAsset: Pick<IBaseAsset, 'type' | 'symbol' | 'name'> } | null;
    };
  }>
): ReactElement | undefined {
  const rowData = getRowDataForRowGroupColumn(params);
  if (!rowData) {
    return undefined;
  }
  const asset = rowData.asset.derivativeDetails?.baseAsset ?? rowData.asset;
  return asset ? <AssetLabel asset={asset} wrap={false} size={IconVariant.MEDIUM} plain link /> : undefined;
}

export function derivativeAssetValueGetter(params: {
  data?: {
    asset: {
      symbol: string;
      name?: string | null | undefined;
      derivativeDetails?: { baseAsset?: { symbol: string } | null | undefined } | null | undefined;
    };
  };
}): string | undefined {
  if (!params.data) {
    return undefined;
  }

  const asset = params.data.asset.derivativeDetails?.baseAsset ?? params.data.asset;

  return getAssetName(asset);
}

export function assetSymbolGetter(params: { data?: { asset: { symbol: string } } }): string | undefined {
  if (!params.data) {
    return undefined;
  }

  return params.data.asset.symbol;
}

export function createAssetNameGetter<T extends string>(field: T): (params: { data?: unknown }) => string | undefined {
  return (params: { data?: unknown }): string | undefined => {
    const val = get(field, params.data);
    if (!val) {
      return undefined;
    }

    return getAssetName(val);
  };
}

export const assetNameGetter = createAssetNameGetter('asset');

export function dateReadableValueGetter(
  date: UtcDate | string,
  format: DateTimeFormat = DateTimeFormat.Date
): string | undefined {
  const localMidnightDate = dayjs.utc(date.toString());
  // the only found way to export correct format to charts is to store value in readable date format
  // but filtering only works with native Date object, so we need to implement it manually bellow
  return formatDate(localMidnightDate, format, UTC);
}

export const createColumnToolDef = (panelParams: {
  suppressRowGroups?: boolean;
  suppressValues?: boolean;
  suppressPivots?: boolean;
  suppressPivotMode?: boolean;
}) => ({
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: panelParams,
});
