import { IConstraintFormulation, type IYieldOptimizerResultQuery } from '../../../../../../generated/graphql.tsx';
import type { ReactElement, ReactNode } from 'react';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import CardWithHeader from '../CardWithHeader.tsx';
import ValueWithLabel from '../ValueWithLabel.tsx';
import { Stack } from '@mui/joy';
import GTable from '../../../../../technical/GTable/GTable.tsx';
import { createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import { formatNumber } from '../../../../../formatter.utils.ts';
import { formatConstraintBound } from '../OptimizerInputForResult.utils.ts';

type Input = IYieldOptimizerResultQuery['optimization']['getOptimizationInput'];
type AmountRow = Input['amounts'][number];
type PoolRow = Input['pools'][number];

const amountColumnHelper = createColumnHelper<AmountRow>();
const poolColumnHelper = createColumnHelper<PoolRow>();
const YieldOptimizerInput = ({ data }: { data: IYieldOptimizerResultQuery }): ReactElement => {
  const optimization = data.optimization.getOptimizationInput;
  return (
    <Stack gap={defaultRowSpacing}>
      <Stack flexWrap="wrap" direction="row" gap={defaultRowSpacing}>
        <CardWithHeader title="Description">
          <ValueWithLabel label="Name">{optimization.name}</ValueWithLabel>
          <ValueWithLabel label="Description">{optimization.description}</ValueWithLabel>
        </CardWithHeader>
      </Stack>
      <CardWithHeader title="Assets">
        <GTable
          data={optimization.amounts}
          columns={[
            amountColumnHelper.accessor('assetId', {
              header: 'Asset',
              meta: {
                headerStyles: {
                  width: '300px',
                  minWidth: '100px',
                },
              },
            }),
            {
              cell: (props: CellContext<AmountRow, unknown>): ReactNode => formatNumber(props.row.original.amount),
              header: 'Amount',
              accessorFn: (value) => value.amount,
            },
          ]}
        />
      </CardWithHeader>
      <CardWithHeader title="Pools">
        <GTable
          data={optimization.pools}
          columns={[
            poolColumnHelper.accessor('poolId', {
              header: 'Pool',
              meta: {
                headerStyles: {
                  width: '300px',
                  minWidth: '100px',
                },
              },
            }),
            poolColumnHelper.accessor(
              (info) =>
                info.constraint
                  ? formatConstraintBound({
                      constraint: info.constraint,
                      constraintFormulation: IConstraintFormulation.AbsoluteValue,
                      usdValue: false,
                    })
                  : undefined,
              {
                header: 'Constraint',
              }
            ),
          ]}
        />
      </CardWithHeader>
    </Stack>
  );
};

export default YieldOptimizerInput;
