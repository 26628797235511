import {
  IConstraintFormulation,
  IOptimizationMode,
  type ISubmitYieldOptimizationMutationVariables,
} from '../../../../../generated/graphql.tsx';
import { getConstraintValue } from '../requestFactory.ts';
import type { YieldOptimizerOutputFields } from './YieldOptimizer.validation.ts';
import { bignumber } from 'mathjs';

export const createRequestInput = (
  output: YieldOptimizerOutputFields
): ISubmitYieldOptimizationMutationVariables['input'] => {
  const constraintPerPool = new Map(output.constraints.map((cons) => [cons.pool.id, cons]));

  return {
    name: output.name,
    description: output.description,
    optimizationMode: IOptimizationMode.Conservative,
    poolPreferences: output.universe.map((pool) => {
      const constraint = constraintPerPool.get(pool.id);
      return {
        poolId: pool.id,
        constraint: constraint
          ? getConstraintValue(
              { constraintValue: constraint.constraintValue, constraintType: constraint.constraintType },
              IConstraintFormulation.AbsoluteValue
            )
          : null,
      };
    }),
    amounts: Object.values(output.givenPortfolio).map((asset) => ({
      assetId: asset.asset.id,
      amount: bignumber(asset.amount).toNumber(),
    })),
  };
};
