import { useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import type { PortfolioBuilderInputFields } from './PortfolioBuilder.utils.ts';
import DialogButton from 'components/technical/inputs/GButton/DialogButton.tsx';
import CreateRebalancedPortfolioDialog from '../lab/definition/rebalancedPortfolio/CreateRebalancedPortfolioDialog.tsx';
import { Save } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { IPortfolioDefinitionSubType } from 'generated/graphql.tsx';
import { Stack } from '@mui/joy';

const CreatePortfolioBuilderButton = () => {
  const navigate = useNavigate();
  const { formState, watch } = useFormContext<PortfolioBuilderInputFields>();

  const positions = watch('positions');

  return (
    <Stack direction="row" spacing={1} height={10}>
      <DialogButton
        variant="solid"
        width="normal"
        disabled={Object.keys(formState.errors).length > 0}
        renderDialog={({ onClose }): ReactElement => (
          <CreateRebalancedPortfolioDialog
            onClose={onClose}
            onAdded={() => {
              navigate('/app/copilot/lab/portfolio');
              onClose();
            }}
            subType={IPortfolioDefinitionSubType.Default}
            composition={Object.values(positions).map((comp) => ({
              id: comp.asset.id,
              weight: Number(comp.weight),
            }))}
          />
        )}
        startDecorator={<Save />}
      >
        Save portfolio
      </DialogButton>
    </Stack>
  );
};

export default CreatePortfolioBuilderButton;
