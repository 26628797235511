import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import type { ColDef } from 'ag-grid-enterprise';
import { createAssetCellRenderer, createAssetNameGetter } from '../../../../technical/grids/agGrid.utils.tsx';
import type { OptimizationOutput } from './YieldOptimizerResult.types.ts';

type RowType = OptimizationOutput['allocations'][number];

const DEFAULT_COL_DEF = {
  resizable: true,
  sortable: true,
  filter: true,
};

const columns: ColDef<RowType>[] = [
  {
    colId: 'assetName',
    headerName: 'Asset',
    type: 'textColumn',
    minWidth: 160,
    valueGetter: createAssetNameGetter('pool.collateralAsset'),
    cellRenderer: createAssetCellRenderer('pool.collateralAsset'),
  },
  {
    colId: 'amount',
    headerName: 'Amount',
    field: 'absoluteAllocationInCollateral',
    type: ['numericColumn'],
  },
  {
    colId: 'amountInUsd',
    headerName: 'Dollar value',
    field: 'dollarValueOfAllocation',
    type: ['numericColumn', 'cashColumn'],
  },
  {
    colId: 'allocationPercentage',
    headerName: 'Amount % wrt asset',
    field: 'percentageAllocationInCollateral',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'allocationPercentageInUsd',
    headerName: 'Amount % wrt equity',
    field: 'percentageAllocationInUsd',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'baseApy',
    headerName: 'Apy',
    field: 'baseApy',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'impactedApy',
    headerName: 'Impacted apy',
    field: 'impactedApy',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'yieldInCollateral',
    headerName: 'Yield in asset',
    field: 'yieldInCollateral',
    type: ['numericColumn'],
  },
  {
    colId: 'yieldInUsd',
    headerName: 'Yield in dollar',
    field: 'yieldInUsd',
    type: ['numericColumn', 'cashColumn'],
  },
  {
    colId: 'pool',
    headerName: 'Pool',
    field: 'pool.poolName',
  },
  {
    colId: 'protocol',
    headerName: 'Protocol',
    field: 'pool.protocol',
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'pool.category',
  },
  {
    colId: 'baseApy',
    headerName: 'Base APY',
    field: 'pool.metrics.apyBase',
    type: ['numericColumn', 'percentageColumn'],
  },
  {
    colId: 'tvl',
    headerName: 'TVL USD',
    field: 'pool.metrics.tvlUsd',
    type: ['numericColumn', 'cashColumn'],
  },
];

const YieldAllocationGrid = ({ data }: { data: RowType[] }) => {
  return (
    <GAgGrid<RowType> height={500} rowData={data} enableCharts defaultColDef={DEFAULT_COL_DEF} columnDefs={columns} />
  );
};

export default YieldAllocationGrid;
