import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { isValidNumber } from '../../../../../number.utils.ts';

export const getAvailableAssetIds = (givenPortfolio: YieldOptimizerInputFields['givenPortfolio']): Set<string> => {
  return new Set(
    Object.values(givenPortfolio)
      .filter(
        (assetValue) =>
          !isNil(assetValue.amount) &&
          isValidNumber(assetValue.amount) &&
          !bignumber(assetValue.amount).isZero() &&
          bignumber(assetValue.amount).isPositive()
      )
      .map((val) => val.asset.id)
  );
};

export const syncInitialAssetsWithPoolsAndConstraints = (
  getValues: UseFormGetValues<YieldOptimizerInputFields>,
  setValue: UseFormSetValue<YieldOptimizerInputFields>
): void => {
  const availableAssetIds = getAvailableAssetIds(getValues('givenPortfolio'));
  const pools = getValues('universe').filter((pool) => availableAssetIds.has(pool.collateralAsset.id));
  const poolIds = new Set(pools.map((pool) => pool.id));
  const origConstraints = getValues('constraints');
  const constraints = origConstraints.filter((con) => {
    if (isNil(con.pool)) {
      return true;
    }

    return poolIds.has(con.pool.id);
  });

  setValue('constraints', constraints, {
    shouldValidate: true,
  });

  setValue('universe', pools, {
    shouldValidate: true,
  });
};
