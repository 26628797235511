import type { OptimizationOutput } from './YieldOptimizerResult.types.ts';
import { useFinalColorScheme } from '../../../../../useFinalColorScheme.ts';
import Message from '../../../../technical/Message.tsx';
import {
  calculateChartInput,
  type ElementChild,
} from '../../../../technical/charts/SunburstChart/SunburstChart.utils.ts';
import { getLightChartColor, getRegularChartColor } from '../../../../../theme/colors.ts';
import { bignumber } from 'mathjs';
import { formatCash } from '../../../../formatter.utils.ts';
import bigNumMath from '../../../../../bigNumMath.ts';
import SunburstChart from '../../../../technical/charts/SunburstChart/SunburstChart.tsx';
import groupBy from 'lodash/fp/groupBy';

type RowType = OptimizationOutput['allocations'][number];

const YieldAllocationSunburst = ({ data }: { data: RowType[] }) => {
  const colorScheme = useFinalColorScheme();
  if (data.length === 0) {
    return <Message>No data</Message>;
  }

  // first level asset, snd pool
  const assetPools = groupBy((row: RowType) => row.pool.collateralAsset.id, data);
  const sunburstData = calculateChartInput<{ value: number } & ElementChild>({
    root: {
      label: 'Total',
      color: 'transparent',
      elements: Object.entries(assetPools).map(([_, rows], i) => {
        const firstRow = rows[0];
        const pool = firstRow.pool;
        return {
          label: pool.collateralAsset.name ?? pool.collateralAsset.symbol,
          color: getRegularChartColor(colorScheme, i),
          value: bigNumMath.sum(rows.map((row) => row.dollarValueOfAllocation)),
          elements: rows.map((row) => ({
            label: row.pool.poolName,
            value: row.dollarValueOfAllocation,
            color: getLightChartColor(colorScheme, i),
            elements: [],
          })),
        };
      }),
    },
    valueProvider: (val) => bignumber(val.value),
    textProvider: (val) => formatCash(val),
    rootValue: bigNumMath.sum(data.map((row) => bignumber(row.dollarValueOfAllocation))),
  });
  return <SunburstChart data={{ ...sunburstData, hoverinfo: 'label+text', textinfo: 'label+text' }} />;
};

export default YieldAllocationSunburst;
