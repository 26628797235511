import type { ReactElement } from 'react';
import { Grid, Stack, Typography } from '@mui/joy';
import Tile from 'components/technical/Tile/Tile';

const YieldResultTile = ({ value, title }: { value: string; title: string }): ReactElement => {
  return (
    <Grid xs={12} md={4}>
      <Tile title={title} fullHeight>
        <Stack justifyContent="space-between" height="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Typography level="h2" component="span">
              {value}
            </Typography>
          </Stack>
        </Stack>
      </Tile>
    </Grid>
  );
};

export default YieldResultTile;
