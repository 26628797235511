import { Popper } from '@mui/base/Popper';
import { AutocompleteListbox } from '@mui/joy';
import sum from 'lodash/fp/sum';
import unset from 'lodash/fp/unset';
import React, { type ForwardedRef, type ReactElement, type ReactNode, useContext, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { LISTBOX_ITEM_PADDING_PX, renderRow, type RowProps } from './RenderRow.tsx';
import { LISTBOX_PADDING_PX, SEE_MORE_HEIGHT } from './renderRow.utils.ts';
import { VirtualizedListContext } from './VirtualizedListContext.tsx';
import widthSx from '../../../../width.styles.ts';
import isNil from 'lodash/fp/isNil';

const AUTOCOMPLETE_MENU_HEIGHT = 400; // material ui default

const OuterElementContext = React.createContext<
  object & { menuWidth?: 'fullWidth' | 'smaller' | 'small' | 'normal' | 'xl2' | 'xl3' | 'xl4' }
>({
  menuWidth: 'fullWidth',
});

const OuterElementType = React.forwardRef<HTMLDivElement>(
  function OuterAutocompleteContainer(props, ref): ReactElement {
    const { menuWidth, ...outerProps } = React.useContext(OuterElementContext);

    const outerPropsNoStyleWidth = unset('style.width', outerProps);
    return (
      <AutocompleteListbox
        {...props}
        {...(menuWidth === 'fullWidth' ? outerProps : outerPropsNoStyleWidth)}
        component="div"
        ref={ref}
        sx={{
          '& ul': {
            padding: 0,
            margin: 0,
            flexShrink: 0,
          },
          ...(!isNil(menuWidth) && menuWidth !== 'fullWidth' ? widthSx[menuWidth] : undefined),
        }}
      />
    );
  }
);

type Group<T> = {
  key: string;
  group: string;
  children: RowProps<T>[];
};

const calculateItems = <T,>(children: ReactNode, hasGroups: boolean): RowProps<T>[] => {
  // automatically removes loading message and no results
  if (hasGroups) {
    const items = ((children as [Group<T>[]]) ?? [])[0] as Group<T>[];

    // skip groups with blank names. usually used for see more message
    return items.flatMap((group) =>
      group.group
        ? [
            {
              elementProps: {},
              option: {
                type: 'group' as const,
                name: group.group,
                key: group.key,
              },
            } satisfies RowProps<T>,
            ...group.children,
          ]
        : group.children
    );
  }

  // return a copy to not modify the original list later
  return [...((children as [RowProps<T>[]]) ?? [])[0]] as RowProps<T>[];
};

export const VirtualizedListbox = function ListBox<T>(
  props: {
    // biome-ignore lint/suspicious/noExplicitAny:
    anchorEl: any;
    open: boolean;
    ownerState: {
      loading: boolean;
    };
    // biome-ignore lint/suspicious/noExplicitAny:
    modifiers: any[];
    ref?: ForwardedRef<HTMLDivElement>;
  } & React.HTMLAttributes<HTMLElement>
): ReactElement {
  const { children, anchorEl, open, modifiers, ref, ...other } = props;
  const context = useContext(VirtualizedListContext);
  const items = calculateItems<T>(children, context.hasGroups);
  // normally if codebase passes type checking itemHeight cannot be undefined, but if it ever it, it creates a busyloop
  const itemHeights = items.map(
    (item) =>
      (item.option.type === 'regular' ? context.optionHeight ?? SEE_MORE_HEIGHT : SEE_MORE_HEIGHT) +
      LISTBOX_ITEM_PADDING_PX
  );
  const totalItemHeight = sum(itemHeights);
  const listRef = useRef<null | VariableSizeList>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    listRef.current?.resetAfterIndex(0, true);
  }, [items]);

  return (
    // to avoid outside scroll, we need to pass props to an element inside variable size list
    <Popper ref={ref} anchorEl={anchorEl} open={open} modifiers={modifiers} placement="bottom-start">
      <OuterElementContext.Provider
        value={{
          ...other,
          menuWidth: context.menuWidth,
        }}
      >
        <VariableSizeList<RowProps<T>[]>
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemData={items}
          ref={listRef}
          height={Math.min(AUTOCOMPLETE_MENU_HEIGHT, totalItemHeight) + 2 * LISTBOX_PADDING_PX}
          width="100%"
          itemSize={(index: number): number => itemHeights[index]}
          overscanCount={5}
          itemCount={items.length}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
};
