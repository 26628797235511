import { Box, Stack } from '@mui/joy';
import { defaultRowSpacing } from 'components/StackSpacing';
import GAgGrid from 'components/technical/grids/GAgGrid';
import GButton from 'components/technical/inputs/GButton/GButton';
import { type ReactElement, type ReactNode, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles';
import { type BigNumber, bignumber } from 'mathjs';
import { defaultCol } from '../../initialPortfolio/InitialPortfolioStep.utils.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import { getAssetCategory, type NotVerifiedAssetWithId } from '../../../../../market/asset/AssetService.tsx';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FormInput } from '../../../../../technical/inputs';
import { syncInitialAssetsWithPoolsAndConstraints } from './InitialAssetStep.utils.ts';
import { isNil } from 'lodash/fp';
import { nameColumn, symbolColumn } from '../../../../../technical/grids/SharedReportColumns.tsx';
import type { IAsset } from '../../../../../../generated/graphql.tsx';

type Row = { asset: NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'> };
export const InitialAssetInputCellRenderer = (props: ICellRendererParams<Row, ReactNode>): ReactElement | null => {
  const id = props.data?.asset.id;

  const {
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useFormContext<YieldOptimizerInputFields>();

  if (!id) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormInput<YieldOptimizerInputFields>
        type="number"
        name={`givenPortfolio.${id}.amount` as const}
        width="fullWidth"
        errorInTooltip={true}
        disabled={isSubmitting}
        onBlur={() => {
          setValue(`givenPortfolio.${id}.asset`, props.data!.asset);
          syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
        }}
      />
    </Stack>
  );
};

const InitialAssetsStep = ({
  assets,
  goToNextStep,
  aggregatedPortfolioAmountByAsset,
}: {
  assets: (NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>)[];
  goToNextStep: () => void;
  aggregatedPortfolioAmountByAsset: Map<string, BigNumber>;
}): ReactElement => {
  const { getValues, setValue } = useFormContext<YieldOptimizerInputFields>();

  const columns: ColDef<Row>[] = useMemo(
    () => [
      nameColumn({ initialHide: false }),
      symbolColumn({ initialHide: false }),
      {
        colId: 'assetType',
        headerName: 'Type',
        valueGetter: (params: ValueGetterParams<Row, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return getAssetCategory(params.data?.asset);
        },
      },
      {
        colId: 'givenPortfolio',
        headerName: 'Amount',
        cellRenderer: InitialAssetInputCellRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
        filter: 'agNumberColumnFilter',
        valueGetter: (params: ValueGetterParams<Row, number>): number | undefined => {
          if (!params.data?.asset) {
            return undefined;
          }
          const assetId = params.data.asset.id;
          const givenPortfolioAssetInfo = getValues(`givenPortfolio.${assetId}`);
          if (isNil(givenPortfolioAssetInfo)) {
            return undefined;
          }

          const { amount } = givenPortfolioAssetInfo;
          return !amount ? undefined : bignumber(amount).toNumber();
        },
      },
    ],
    [getValues]
  );

  const importPortfolio = () => {
    const importedGivenPortfolioInAmount = Object.fromEntries(
      assets.map(
        (
          asset
        ): [
          string,
          { asset: NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>; amount: string | null },
        ] => {
          const amount = aggregatedPortfolioAmountByAsset.get(asset.id);
          if (isNil(amount)) {
            return [asset.id, { asset: asset, amount: null }];
          }

          const roundedAmount = bignumber(amount).toDP(2);
          if (roundedAmount.isZero()) {
            return [asset.id, { asset: asset, amount: null }];
          }

          return [
            asset.id,
            {
              asset: asset,
              amount: roundedAmount.toString(),
            },
          ];
        }
      )
    );

    setValue('givenPortfolio', importedGivenPortfolioInAmount);
    syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
  };

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack spacing={defaultRowSpacing} direction="row-reverse">
        <GButton
          variant="plain"
          onClick={(): void => {
            const emptyGivenPortfolio = Object.fromEntries(
              assets.map((asset) => [
                asset.id,
                {
                  asset: asset,
                  amount: null,
                },
              ])
            );
            setValue('givenPortfolio', emptyGivenPortfolio);
            syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
          }}
        >
          Clear all
        </GButton>
        <GButton onClick={importPortfolio}>Import from portfolio</GButton>
      </Stack>
      <Box height="500px">
        <GAgGrid<Row>
          rowData={assets.map((asset) => ({ asset }))}
          getRowId={(params) => params.data.asset.id}
          columnDefs={columns}
          defaultColDef={defaultCol}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          rowHeight={gridWithInputStyles.rowHeight}
        />
      </Box>
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default InitialAssetsStep;
