import { Grid, Stack, Typography } from '@mui/joy';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { formatCash, formatNumber, formatPercentage } from '../../../../formatter.utils.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import isNil from 'lodash/fp/isNil';
import YieldResultTile from './YieldResultTile.tsx';
import type { ReactElement } from 'react';
import YieldAllocationGrid from './YieldAllocationGrid.tsx';
import type { Optimization, OptimizationOutput } from './YieldOptimizerResult.types.ts';
import YieldAllocationSunburst from './YieldAllocationSunburst.tsx';
import sortBy from 'lodash/fp/sortBy';
import uniq from 'lodash/fp/uniq';

const gap = 2;
const YieldOptimizerResult = ({ output }: { output: OptimizationOutput }): ReactElement => {
  const apyNaiveRange = sortBy(
    (val) => val,
    uniq(output.naiveAllocationApys.map((all) => output.globalApy - all.impactedApy))
  );

  return (
    <Stack gap={defaultRowSpacing}>
      <Grid container spacing={gap}>
        <YieldResultTile title={'Predicted returns'} value={formatPercentage(output.globalApy)} />
        <YieldResultTile title={'Returns in $ given current price'} value={formatCash(output.yieldInUsd)} />
        <YieldResultTile
          title={'Improvement rate wrt to single pool allocation'}
          value={`${apyNaiveRange.map((val) => formatNumber(100 * val)).join('-')}%`}
        />
      </Grid>
      <Grid container spacing={gap}>
        <Grid xs={12} lg={8}>
          <YieldAllocationGrid data={output.allocations} />
        </Grid>
        <Grid xs={12} lg={4}>
          <Stack alignItems={'center'} direction={'row'} height={'100%'}>
            <YieldAllocationSunburst data={output.allocations} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

const YieldOptimizerResultWrapper = ({ optimization }: { optimization: Optimization }) => {
  const output = optimization.output;
  return (
    <Stack gap={2}>
      <Typography level="h2">{optimization.name}</Typography>
      {isNil(output) ? <Loader /> : <YieldOptimizerResult output={output} />}
    </Stack>
  );
};

export default YieldOptimizerResultWrapper;
