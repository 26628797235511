import { type FunctionComponent, type ReactElement, useCallback, useMemo } from 'react';
import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import { clusterColumn, nameColumn, symbolColumn } from 'components/technical/grids/SharedReportColumns.tsx';
import type { UseReportAssetGroupResultLoaded } from 'components/UseReportAssetGroups.tsx';
import type {
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import type { BuilderPortfolioPosition } from './PortfolioBuilder.tsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateWeight, calculateValue, type PortfolioBuilderInputFields } from './PortfolioBuilder.utils.ts';
import ErrorMessage from 'components/technical/ErrorMessage.tsx';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles.ts';
import { FormInput } from 'components/technical/inputs/index.ts';
import { Stack } from '@mui/joy';
import { isValidNumber } from 'components/number.utils.ts';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

type PositionsGridProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  showValue?: boolean;
};

type FieldSettings = {
  unit?: string;
};

interface PositionGridEditable {
  weight: string | null;
  value: string | null;
}

export const settings: Record<keyof PositionGridEditable, FieldSettings> = {
  weight: {
    unit: '%',
  },
  value: {
    unit: '$',
  },
};

interface ValueOverriddenCellRendererProps {
  rowId: string;
  fieldName: 'weight' | 'value';
}

const ValueOverriddenCellRenderer: FunctionComponent<ValueOverriddenCellRendererProps> = ({ rowId, fieldName }) => {
  const { getValues, setValue } = useFormContext<PortfolioBuilderInputFields>();

  const handleChange = (newValue: string | null) => {
    const portfolioEquity = getValues('portfolioEquity');
    const portfolioEquityNumber = Number(portfolioEquity);

    if (!isValidNumber(portfolioEquityNumber) || portfolioEquityNumber <= 0) {
      console.warn('Invalid portfolio equity:', portfolioEquity);
      return;
    }

    if (fieldName === 'weight' && newValue !== null) {
      const calculatedValue = calculateValue(portfolioEquityNumber, Number.parseFloat(newValue ?? '0'));
      setValue(`positions.${rowId}.value`, calculatedValue);
    } else if (fieldName === 'value' && newValue !== null) {
      const calculatedWeight = calculateWeight(portfolioEquityNumber, Number.parseFloat(newValue ?? '0'));
      setValue(`positions.${rowId}.weight`, calculatedWeight);
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <FormInput<PortfolioBuilderInputFields>
        type="number"
        name={`positions.${rowId}.${fieldName}`}
        placeholder="0"
        width="normal"
        disabled={false}
        errorInTooltip
        startDecorator={settings[fieldName].unit}
        onChange={handleChange}
      />
    </Stack>
  );
};

const CustomSideColumn = (props: ICellRendererParams<BuilderPortfolioPosition, ReactElement>): ReactElement | null => {
  const { data } = props;
  const valueWatch = useWatch<PortfolioBuilderInputFields, `positions.${string}.value`>({
    name: `positions.${data?.asset.id}.value`,
  });
  const sideValue = Number.parseFloat(valueWatch ?? '0') >= 0 ? 'Long' : 'Short';
  return <div>{sideValue}</div>;
};

const CustomFieldRenderer = (
  props: ICellRendererParams<BuilderPortfolioPosition, ReactElement>
): ReactElement | null => {
  const { data, colDef } = props;
  const field = colDef?.field;

  if (!field || !(field in settings)) {
    console.warn('Unsupported field in FieldRenderer:', field);
    return null;
  }

  const rowId = data?.asset.id;

  if (!rowId) {
    console.warn('No rowId in FieldRenderer:', props);
    return null;
  }

  return <ValueOverriddenCellRenderer rowId={rowId} fieldName={field} />;
};

const PositionsGrid: FunctionComponent<PositionsGridProps> = ({ showValue, reportAssetGroup }) => {
  const { getFieldState, getValues } = useFormContext<PortfolioBuilderInputFields>();

  const { error: positionsError } = getFieldState('positions');

  const getRowId = useCallback<GetRowIdFunc>((params: GetRowIdParams<BuilderPortfolioPosition>): string => {
    // Use the asset id directly.
    return params.data.asset.id;
  }, []);

  const columns: (ColDef<BuilderPortfolioPosition> | ColGroupDef<BuilderPortfolioPosition>)[] = useMemo(
    () => [
      {
        headerName: 'Asset Details',
        colId: 'asset-details',
        marryChildren: true,
        children: [
          nameColumn({ initialHide: false }),
          symbolColumn({ initialHide: false }),
          ...reportAssetGroup.clusters.map((cluster) =>
            clusterColumn(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
          ),
        ],
      },
      {
        colId: 'side',
        headerName: 'Side',
        type: 'textColumn',
        width: 140,
        cellRenderer: CustomSideColumn,
        valueGetter: (params: ValueGetterParams<BuilderPortfolioPosition>): string => {
          if (!params.data) return '';

          const valueWatch = getValues(`positions.${params.data.asset.id}.value`);
          const numericValue = Number.parseFloat(valueWatch ?? '0');

          return numericValue >= 0 ? 'Long' : 'Short';
        },
        comparator: (valueA, valueB) => {
          if (valueA === 'Long' && valueB === 'Short') return -1;
          if (valueA === 'Short' && valueB === 'Long') return 1;
          return 0;
        },
      },
      {
        headerName: 'Current positions',
        colId: 'currentPositions',
        marryChildren: true,

        children: [
          {
            headerName: 'Weight',
            initialSort: 'desc',
            field: 'weight',
            width: 160,
            valueGetter: (params: ValueGetterParams<BuilderPortfolioPosition>): number => {
              if (!params.data) return 0;
              const weight = getValues(`positions.${params.data.asset.id}.weight`);
              return Number.parseFloat(weight ?? '0');
            },
            cellRenderer: CustomFieldRenderer,
            cellStyle: gridWithInputStyles.inputCellStyle,
          },
          ...(showValue
            ? [
                {
                  headerName: 'Value',
                  field: 'value',
                  width: 160,
                  valueGetter: (params: ValueGetterParams<BuilderPortfolioPosition>): number => {
                    if (!params.data) return 0;
                    const value = getValues(`positions.${params.data.asset.id}.value`);
                    return Number.parseFloat(value ?? '0');
                  },
                  cellRenderer: CustomFieldRenderer,
                  cellStyle: gridWithInputStyles.inputCellStyle,
                },
              ]
            : []),
        ],
      },
    ],
    [reportAssetGroup.clusters, reportAssetGroup.assetAndGroupClusterMapToGroup, showValue, getValues]
  );

  const positions = useWatch<PortfolioBuilderInputFields, 'positions'>({
    name: 'positions',
  });

  return (
    <>
      <GAgGrid<BuilderPortfolioPosition>
        rowData={Object.values(positions)}
        columnDefs={columns}
        overlayNoRowsTemplate={'Add or import data'}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowHeight={gridWithInputStyles.rowHeight}
      />
      {positionsError && <ErrorMessage>{positionsError.message}</ErrorMessage>}
    </>
  );
};

export default PositionsGrid;
