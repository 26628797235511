import { bignumber } from 'mathjs';

import { ErrorType } from './ErrorTypes.ts';
import { useErrorsQuery } from '../../../generated/graphql.tsx';
import { minSnapshotDiscrepancyUSD } from '../../bookkeeping/reconciliation/Reconciliation.utils.ts';
import { useDefaultErrorHandling } from '../UseDefaultErrorHandling.tsx';
import isNil from 'lodash/fp/isNil';

export type PollingProps = {
  startPolling: (ms: number) => void;
  stopPolling: () => void;
};

export type useHasErrorDataProps =
  | {
      errorTypes: undefined;
      loaded: false;
    }
  | {
      errorTypes: ErrorType[];
      loaded: true;
    };

export type useHasErrorOutput = useHasErrorDataProps;
export const useHasErrors = (props?: { pollInterval?: number }): useHasErrorOutput => {
  const { loaded, data } = useDefaultErrorHandling(
    useErrorsQuery({
      pollInterval: props?.pollInterval,
      variables: {
        minSnapshotDiscrepancyUSD: bignumber(minSnapshotDiscrepancyUSD).toString(),
      },
    })
  );
  if (!loaded) {
    return {
      loaded: false,
      errorTypes: undefined,
    };
  }

  const errors: ErrorType[] = [];
  const journal = data.portfolio.journal;
  const hasJournalError = !isNil(journal.anyJournalError);

  if (hasJournalError) {
    errors.push(ErrorType.JOURNAL);
  }

  const reconciliationError = data.bookkeeping.reconciliation.report.length > 0;
  if (reconciliationError) {
    errors.push(ErrorType.RECONCILIATION);
  }

  return {
    loaded: true,
    errorTypes: errors,
  };
};
