import { Stack } from '@mui/joy';
import type { ColDef } from 'ag-grid-community';
import type { ReactElement } from 'react';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import Universe from '../../universe/Universe.tsx';
import type { IYieldOptimizerWizardInputQuery } from 'generated/graphql.tsx';
import {
  getAvailableAssetIds,
  syncInitialAssetsWithPoolsAndConstraints,
} from '../initialAssets/InitialAssetStep.utils.ts';
import { createNameColumn } from '../../../../../technical/grids/SharedReportColumns.tsx';
import { useWatch } from 'react-hook-form';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';

export type UniversePool = IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'][number];

const columns: ColDef<UniversePool>[] = [
  {
    colId: 'pool',
    headerName: 'Pool',
    field: 'poolName',
    initialWidth: 250,
  },
  {
    colId: 'protocol',
    headerName: 'Protocol',
    field: 'protocol',
    initialWidth: 200,
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'category',
    initialWidth: 200,
  },
  createNameColumn('collateralAsset', {
    initialHide: false,
    columnName: 'Asset',
    initialWidth: 200,
  }),
  {
    colId: 'baseApy',
    headerName: 'Base APY',
    field: 'metrics.apyBase',
    type: ['numericColumn', 'percentageColumn'],
    initialWidth: 200,
  },
  {
    colId: 'tvl',
    headerName: 'TVL USD',
    field: 'metrics.tvlUsd',
    type: ['numericColumn', 'cashColumn'],
    initialWidth: 200,
  },
];

const PoolUniverseStep = ({
  pools,
  goToNextStep,
}: {
  pools: IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'];
  goToNextStep: () => void;
}): ReactElement => {
  const assets = useWatch<YieldOptimizerInputFields, 'givenPortfolio'>({
    name: 'givenPortfolio',
  });

  const avAssetIds = getAvailableAssetIds(assets);

  return (
    <Stack spacing={defaultRowSpacing}>
      <Universe<IYieldOptimizerWizardInputQuery['yieldOptimization']['listPools'][number], YieldOptimizerInputFields>
        columnDefs={columns}
        rowData={pools.filter((pool) => avAssetIds.has(pool.collateralAsset.id))}
        idGetter={(row) => row?.id ?? ''}
        syncSelection={({ getValues, setValue }) => {
          syncInitialAssetsWithPoolsAndConstraints(getValues, setValue);
        }}
      />
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default PoolUniverseStep;
