import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import AddButton from 'components/technical/AddButton.tsx';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';

import { ConstraintType } from '../../ConstraintTypeValues.validation.ts';
import AllocationRow from './AllocationRow.tsx';
import StepDescription from '../../../../../technical/wizard/StepDescription.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';

const AllocationConstraintsStep = ({
  goToNextStep,
}: {
  goToNextStep: () => void;
}): ReactElement => {
  const { formState } = useFormContext<YieldOptimizerInputFields>();

  const activePools = useWatch<YieldOptimizerInputFields, 'universe'>({
    name: 'universe',
  });

  const {
    fields: constraints,
    remove,
    append,
  } = useFieldArray<YieldOptimizerInputFields, 'constraints'>({
    name: 'constraints',
  });

  const watchedConstraints = useWatch<YieldOptimizerInputFields, 'constraints'>({
    name: 'constraints',
  });

  return (
    <Stack spacing={defaultRowSpacing}>
      <StepDescription>
        Here you can specify constraints on allocation on asset pools. You can express limits to their allocation
      </StepDescription>
      <Stack direction="column" spacing={defaultRowSpacing} alignItems="flex-start">
        {constraints.map((constraint, index) => (
          <AllocationRow
            key={constraint.id}
            activePools={activePools}
            constraints={watchedConstraints}
            index={index}
            onRemove={(): void => {
              remove(index);
            }}
          />
        ))}
        <AddButton
          disabled={formState.isSubmitting}
          onClick={(): void => {
            append({
              pool: null,
              constraintType: ConstraintType.Equal,
              constraintValue: {
                value: '',
                min: '',
                max: '',
              },
            });
          }}
        >
          Add new allocation
        </AddButton>
      </Stack>

      <GButton
        onClick={goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};

export default AllocationConstraintsStep;
