import { AutocompleteOption } from '@mui/joy';
import type React from 'react';
import type { CSSProperties, ReactElement, ReactNode } from 'react';

type AutocompleteOptionProps = Omit<React.HTMLAttributes<HTMLLIElement>, 'color'> & { style: CSSProperties };
const TextOption = (props: AutocompleteOptionProps & { children: ReactNode }): ReactElement => {
  const { children, ...rest } = props;
  return <AutocompleteOption {...rest}>{children}</AutocompleteOption>;
};
export const MoreResultsItem = (props: AutocompleteOptionProps): ReactElement => {
  return <TextOption {...props}>Only top results shown</TextOption>;
};

export const NoResultsItem = (props: AutocompleteOptionProps): ReactElement => {
  return <TextOption {...props}>No results</TextOption>;
};

export const LoadingResultsItem = (props: AutocompleteOptionProps): ReactElement => {
  return <TextOption {...props}>Loading...</TextOption>;
};

export const TypeMoreResultsItem = (props: AutocompleteOptionProps): ReactElement => {
  return <TextOption {...props}>Type to search...</TextOption>;
};
