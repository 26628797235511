import InitialAssetsStep from './InitialAssetsStep.tsx';
import type { StepConfig } from 'components/technical/wizard/StepConfig';

import { fields } from '../../initialPortfolio/InitialPortfolioStepConfig.tsx';
import type { YieldOptimizerInputFields } from '../YieldOptimizer.validation.ts';
import type { BigNumber } from 'mathjs';
import type { NotVerifiedAssetWithId } from 'components/market/asset/AssetService.tsx';
import type { IAsset } from 'generated/graphql.tsx';

export const config = (
  assets: (NotVerifiedAssetWithId & Pick<IAsset, 'symbol' | 'name' | 'type'>)[],
  aggregatedPortfolioAmountByAsset: Map<string, BigNumber>,
  goToNextStep: () => void
): StepConfig<YieldOptimizerInputFields> => ({
  label: 'Initial assets',
  element: (
    <InitialAssetsStep
      assets={assets}
      aggregatedPortfolioAmountByAsset={aggregatedPortfolioAmountByAsset}
      goToNextStep={goToNextStep}
    />
  ),
  fields,
});
